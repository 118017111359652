<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form
      @submit.stop.prevent="handleSubmit(updateBusinessSetting)"
      ref="updateDisplaySettingContainer"
    >
      <div class="row display-settings-row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 mt-3">
          <div class="profileInfo-block column-border">
            <h2>Display Setting</h2>
            <input-text
              :rules="{ required: false }"
              labelFor="display-name"
              labelName="Display Name"
              inputName="name"
              :vmodel.sync="form.agent_web_name"
              formGroupClass="form-group mb-3"
              inputClass="profileInfo-field"
              placeholder="Enter Display Name"
            />
            <input-text
              :rules="{ required: false, digits: 10, clearoutPhone: true }"
              labelFor="phone"
              labelName="Display Phone"
              inputName="phone"
              :vmodel.sync="form.display_phone"
              inputTypes="number"
              formGroupClass="form-group mb-3"
              inputClass="profileInfo-field"
              placeholder="Enter Phone"
            />
            <input-text
              :rules="{ required: false, neverbounce: true }"
              labelFor="email"
              labelName="Display Email"
              inputName="email"
              :vmodel.sync="form.display_email"
              inputTypes="email"
              formGroupClass="form-group mb-3"
              inputClass="profileInfo-field"
              placeholder="Enter Email Address"
            />

            <input-text
              labelFor="tagline"
              labelName="Tagline"
              inputName=" "
              :vmodel.sync="form.tagline"
              formGroupClass="form-group mb-3"
              inputClass="profileInfo-field"
              placeholder="Enter Tagline.."
            />

            <div class="mb-3 display-settings-img">
              <label for=""><em>Where can I see this information?</em></label>
              <img src="@/assets/images/display-setting.png" alt="" />
            </div>
          </div>
        </div>

        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 mt-3">
          <div class="profileInfo-block">
            <h2>Custom Setting</h2>
            <input-text
              labelFor="banner_text"
              labelName="Banner Text"
              inputName="banner text"
              :vmodel.sync="form.banner_text"
              formGroupClass="form-group mb-3"
              inputClass="profileInfo-field"
              placeholder="Banner Text"
            />

            <input-text
              labelFor="help_text"
              labelName="Help Text"
              inputName="help text"
              :vmodel.sync="form.help_text"
              formGroupClass="form-group mb-3"
              inputClass="profileInfo-field"
              placeholder="Help Text"
            />

            <div class="mb-3 display-settings-img">
              <label for=""><em>Where can I see this information?</em></label>
              <img src="@/assets/images/banner-home.jpg" alt="" />
              <img src="@/assets/images/helptext.jpg" alt="" />
            </div>
          </div>
        </div>
        <br />

        <div class="col-12">
          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <button type="submit" class="univ-btn">
                <template v-if="isUpdate"> Updating ... </template>
                <template v-else> Update </template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "DisplaySetting",
  props: {
    displaySettings: {
      required: true,
    },
  },
  data: () => ({
    form: {
      display_phone: "",
      display_email: "",
      banner_text: "",
      help_text: "",
      tagline: null,
      agent_web_name: ""
    },
    isUpdate: false,
    validPhone: true,
    validEmail: true,
  }),
  methods: {
    updateBusinessSetting() {
      const url = "v2/update-display-setting";
      let loader = this.$loading.show({
        container: this.$refs.updateDisplaySettingContainer,
      });
      this.isUpdate = true;
      Api.put(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          this.$refs.observer.reset()
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
  },
  watch: {
    displaySettings: function (value) {
      this.form = {
        display_phone: value.phone ? value.phone : "",
        display_email: value.email ? value.email : "",
        banner_text: value.banner_text ? value.banner_text : "",
        help_text: value.help_text ? value.help_text : "",
        tagline: value.tagline ? value.tagline : "",
        agent_web_name: value.agent_web_name ? value.agent_web_name : ""
      };
    },
  },
};
</script>