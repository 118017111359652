<template>
  <div class="page-wrapper profile-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>My Profile</h1>
      </div>
      <div class="row profileInfo-card">
        <div class="col-12">
          <div class="ds-accInfo-card">
            <b-card no-body>
              <b-tabs card>
                <b-tab title="My Profile" :active="activeBusinessTab">
                  <b-card-text>
                    <business-setting :businessSettings="businessSettings" />
                  </b-card-text>
                </b-tab>
                <b-tab title="Display Settings" :active="activeDisplayTab">
                  <b-card-text>
                    <display-setting :displaySettings="displaySettings" />
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import DisplaySetting from "./DisplaySetting.vue";
import BusinessSetting from "./BusinessSetting.vue";
export default {
  components: { BusinessSetting, DisplaySetting },
  name: "Setting",
  data: () => ({
    activeBusinessTab: true,
    activeDisplayTab: false,
    businessSettings: null,
    displaySettings: null,
    breadcrumbItems: [{ title: "My Profile", active: true }],
  }),
  methods: {
    setActiveDisplayTab() {
      this.activeBusinessTab = false;
      this.activeDisplayTab = true;
    },
    setActiveBusinessTab() {
      this.activeBusinessTab = true;
      this.activeDisplayTab = false;
    },
    getData() {
      const url = `v2/get-user-setting`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.businessSettings = data.business;
          this.displaySettings = data.display_setting;
        })
        .catch(() => {
          this.businessSettings = null;
          this.displaySettings = null;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    if (this.$route.params.tab == "display") {
      this.setActiveDisplayTab();
    } else {
      this.setActiveBusinessTab();
    }
    this.getData();
  },
};
</script>
<style src="@/assets/css/profile.css"></style>