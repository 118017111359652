var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "updateDisplaySettingContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.updateBusinessSetting);
            }
          }
        }, [_c('div', {
          staticClass: "row display-settings-row"
        }, [_c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-12 mt-3"
        }, [_c('div', {
          staticClass: "profileInfo-block column-border"
        }, [_c('h2', [_vm._v("Display Setting")]), _c('input-text', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "display-name",
            "labelName": "Display Name",
            "inputName": "name",
            "vmodel": _vm.form.agent_web_name,
            "formGroupClass": "form-group mb-3",
            "inputClass": "profileInfo-field",
            "placeholder": "Enter Display Name"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "agent_web_name", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false,
              digits: 10,
              clearoutPhone: true
            },
            "labelFor": "phone",
            "labelName": "Display Phone",
            "inputName": "phone",
            "vmodel": _vm.form.display_phone,
            "inputTypes": "number",
            "formGroupClass": "form-group mb-3",
            "inputClass": "profileInfo-field",
            "placeholder": "Enter Phone"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "display_phone", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false,
              neverbounce: true
            },
            "labelFor": "email",
            "labelName": "Display Email",
            "inputName": "email",
            "vmodel": _vm.form.display_email,
            "inputTypes": "email",
            "formGroupClass": "form-group mb-3",
            "inputClass": "profileInfo-field",
            "placeholder": "Enter Email Address"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "display_email", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "labelFor": "tagline",
            "labelName": "Tagline",
            "inputName": " ",
            "vmodel": _vm.form.tagline,
            "formGroupClass": "form-group mb-3",
            "inputClass": "profileInfo-field",
            "placeholder": "Enter Tagline.."
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "tagline", $event);
            }
          }
        }), _c('div', {
          staticClass: "mb-3 display-settings-img"
        }, [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_c('em', [_vm._v("Where can I see this information?")])]), _c('img', {
          attrs: {
            "src": require("@/assets/images/display-setting.png"),
            "alt": ""
          }
        })])], 1)]), _c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-12 mt-3"
        }, [_c('div', {
          staticClass: "profileInfo-block"
        }, [_c('h2', [_vm._v("Custom Setting")]), _c('input-text', {
          attrs: {
            "labelFor": "banner_text",
            "labelName": "Banner Text",
            "inputName": "banner text",
            "vmodel": _vm.form.banner_text,
            "formGroupClass": "form-group mb-3",
            "inputClass": "profileInfo-field",
            "placeholder": "Banner Text"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "banner_text", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "labelFor": "help_text",
            "labelName": "Help Text",
            "inputName": "help text",
            "vmodel": _vm.form.help_text,
            "formGroupClass": "form-group mb-3",
            "inputClass": "profileInfo-field",
            "placeholder": "Help Text"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "help_text", $event);
            }
          }
        }), _c('div', {
          staticClass: "mb-3 display-settings-img"
        }, [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_c('em', [_vm._v("Where can I see this information?")])]), _c('img', {
          attrs: {
            "src": require("@/assets/images/banner-home.jpg"),
            "alt": ""
          }
        }), _c('img', {
          attrs: {
            "src": require("@/assets/images/helptext.jpg"),
            "alt": ""
          }
        })])], 1)]), _c('br'), _c('div', {
          staticClass: "col-12"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
        }, [_c('button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm.isUpdate ? [_vm._v(" Updating ... ")] : [_vm._v(" Update ")]], 2)])])])])])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }